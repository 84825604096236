import React from 'react';
import styled from 'styled-components';

import Email from '../assets/icons/email.png';
import Phone from '../assets/icons/call.png';
import Location from '../assets/icons/location.png';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  margin: 15px 0;
`;

const ContactLineWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  font-size: 1.4rem;
  ${({ theme }) => theme.media.tablet} {
    font-size: 2.5rem;
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: 2.5rem;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
  }
`;

const StyledSpan = styled.span`
  padding: 5px 0;
  text-align: center;
  ${({ theme }) => theme.media.desktop} {
    padding: 20px;
  }
`;

const StyledA = styled.a`
  text-decoration: none;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkorange};
  cursor: pointer;
  ${({ theme }) => theme.media.desktop} {
    transition: all 0.3s ease-in-out;
    &:hover {
      color: red;
      font-size: 2.3rem;
    }
  }
`;
const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: 5px 0;
  &.map {
    height: 35px;
  }
  ${({ theme }) => theme.media.tablet} {
    width: 40px;
    height: 40px;
    &.map {
      height: 50px;
    }
  }
`;

const H2 = styled.h2`
  margin: 5px;
  text-align: center;
  padding: 0;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.brown};
  ${({ theme }) => theme.media.tablet} {
    font-size: 3rem;
  }
  ${({ theme }) => theme.media.bigDesktop} {
    font-size: 4rem;
  }
`;

const ContactDetails = () => (
  <Wrapper data-sal="fade" data-sal-duration="1000" data-sal-easing="ease">
    <H2>Dane Kontaktowe</H2>
    <ContactLineWrapper>
      <Icon
        width="35"
        height="30"
        className="map"
        src={Location}
        alt="location"
      />
      <StyledSpan>
        <StyledA href="https://www.google.com/maps/place/Restauracja+Orientalna+Smaki+Azji,+%C5%9Al%C4%85ska+39,+41-100+Siemianowice+%C5%9Al%C4%85skie/@50.3057855,19.0261477,17z/data=!4m2!3m1!1s0x4716d191625bfa25:0x237f0a53c37161f3">
          <span>
            {' '}
            Siemianowice Śląskie
            <br /> ul.Śląska 39 (obok biedronki)
          </span>
        </StyledA>
      </StyledSpan>
    </ContactLineWrapper>
    <ContactLineWrapper>
      <Icon width="30" height="30" src={Phone} alt="phone" />
      <StyledSpan>
        <StyledA href="tel:+48502083105">+ 48 502 083 105</StyledA>
      </StyledSpan>
    </ContactLineWrapper>
    <ContactLineWrapper>
      <Icon width="30" height="30" src={Email} alt="email" />
      <StyledSpan>
        <StyledA href="mailto:smakiazjisiemianowice@gmail.com">
          smakiazjisiemianowice@gmail.com
        </StyledA>
      </StyledSpan>
    </ContactLineWrapper>
  </Wrapper>
);

export default ContactDetails;

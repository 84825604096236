import React from 'react';
import { graphql } from 'gatsby';
import GoogleMaps from '../components/GoogleMaps';
import DashLine from '../components/DashLine';
import OpenHours from '../components/OpenHours';
import ContactDetails from '../components/ContactDetails';
import Layout from '../components/Layout/Layout';

export type Props = {
  data?: any;
};

const Kontakt = ({ data: { allContentfulImages } }: Props) => {
  const logo = allContentfulImages.edges[0].node.logo.file.url;
  const backgroundDesktop =
    allContentfulImages.edges[0].node.backgrounds[0].file.url;
  return (
    <Layout
      title="Kontakt"
      logo={logo}
      backgroundDesktop={backgroundDesktop}
      description="Skontaktuj się z nami!"
      ogTitle="Kontakt | Smaki Azji Siemianowice Śląskie"
      ogContent="Skontaktuj się z nami, lub odwiedź nas na miejscu! Smaki Azji Siemianowice Śląskie ul.Śląska 39"
    >
      <GoogleMaps />
      <DashLine color="#5E3E28" />
      <OpenHours />
      <DashLine color="#5E3E28" />
      <ContactDetails />
    </Layout>
  );
};

export const getImages = graphql`
  query {
    allContentfulImages {
      edges {
        node {
          logo {
            file {
              url
            }
          }
          backgrounds {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default Kontakt;

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
`;
const StyledIFrame = styled.iframe`
  margin-bottom: 40px;
  border: none;
  width: 100%;
  height: 400px;
  box-shadow: 0 2px 5px ${({ theme }) => theme.colors.gray};
  border-radius: 10px;
  ${({ theme }) => theme.media.tablet} {
    height: 500px;
  }
`;

const H1 = styled.h1`
  margin: 20px;
  color: ${({ theme }) => theme.colors.brown};
  font-size: 2.5rem;
  ${({ theme }) => theme.media.tablet} {
    font-size: 3.5rem;
    margin: 40px;
  }
  ${({ theme }) => theme.media.bigDesktop} {
    font-size: 4rem;
  }
`;

const GoogleMaps = () => (
  <Wrapper data-sal="zoom-out" data-sal-duration="1000" data-sal-easing="ease">
    <H1>Znajdź nas!</H1>
    <StyledIFrame
      allowFullScreen
      loading="lazy"
      src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJJfpbYpHRFkcR82Fxw1MKfyM&key=AIzaSyD1YJ-D7Hx_QfJ1247KQWixVqTxMcV5ll8"
    />
  </Wrapper>
);

export default GoogleMaps;

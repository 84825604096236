import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 90%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.darkorange};
  ${({ theme }) => theme.media.tablet} {
    font-size: 2.6rem;
  }
`;
const H2 = styled.h2`
  font-size: 2.5rem;
  margin: 15px;
  color: ${({ theme }) => theme.colors.brown};
  ${({ theme }) => theme.media.tablet} {
    font-size: 3.5rem;
  }
  ${({ theme }) => theme.media.bigDesktop} {
    font-size: 4rem;
  }
`;

const OpenHours = () => (
  <Wrapper>
    <H2>Godziny otwarcia</H2>
    <span>Pon. - Nd.</span>
    <span>11.00 - 20.00</span>
  </Wrapper>
);

export default OpenHours;
